import axios from "axios";
export class WPServiceFactory {
  baseUrl = "https://www.fransstudio.com/wp-json/wp/v2";
  // process.env.NODE_ENV === "production"
  //   ? "https://www.fransstudio.com/wp-json/wp/v2"
  //   : "https://www.fransstudio.de/wp-json/wp/v2";
  postsUrl = this.baseUrl + "/posts";
  pageUrl = this.baseUrl + "/pages";
  categoriesUrl = this.baseUrl + "/categories";
  worksUrl = this.postsUrl + "?categories_exclude=1,4&_embed";
  mediaUrl = this.baseUrl + "/media";

  async loadPage(options: any = {}) {
    const resp = await axios.get(this.pageUrl);
    return resp.data;
  }

  async loadPageById(id: number | string) {
    const response = await axios.get(this.pageUrl + "/" + id);
    return response.data;
  }

  async loadPosts(page = 1): Promise<any> {
    return new Promise((resolve) => {
      axios
        .get(`${this.postsUrl}?page=${page}&per_page=10`)
        .then((response) => {
          resolve({
            posts: response.data,
            totalPages: response.headers["x-wp-totalpages"],
            totalPosts: response.headers["x-wp-total"],
          });
        });
    });
  }

  async loadPostById(pageId: number): Promise<any> {
    return (await axios.get(`${this.postsUrl}/${pageId}`)).data;
  }

  async loadPostBySlug(slug: string): Promise<any> {
    return (await axios.get(`${this.postsUrl}?slug=${slug}`)).data;
  }


  async loadCategories() {
    const resp = await axios.get(this.categoriesUrl);
    return resp.data;
  }

  loadWorks(page = 1): Promise<any> {
    return new Promise((resolve) => {
      axios.get(this.worksUrl + `&page=${page}`).then((response) => {
        const works = response.data.map(
          (work: any) => (
            (work.image = work._embedded["wp:featuredmedia"][0]), work
          )
        );
        resolve({
          works,
          totalPages: response.headers["x-wp-totalpages"],
        });
      });
    });
  }

  loadImageByGUID(id: number) {
    return axios.get(this.mediaUrl + "/" + id);
    // return this.getDataFromUrlAsJson(this.mediaUrl + "/" + id);
  }
}
// why doing Factory pattern? so we have types? singleton?
// Class functions cant be called without instancing it
export const WPService = new WPServiceFactory();

// This will be without Singleotn Class Factory:
// const Frans = {
//   myVars: 1,
//   myMethods: () => {
//     Frans.myVars * 2;
//   },
// };
