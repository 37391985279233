





















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Blog extends Vue {
  loading = true;
  currentPage = 1;

  get posts() {
    return this.$store.state.posts;
  }
  get page() {
    return this.$store.state.page;
  }
  get totalPages() {
    return this.$store.state.postsTotalPages;
  }

  mounted() {
    if (this.posts.length === 0) {
      this.$store.dispatch("loadPosts", 1).then(() => (this.loading = false));
    } else {
      this.loading = false;
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loading = true;
      this.$store
        .dispatch("loadPosts", this.currentPage)
        .then(() => (this.loading = false));
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loading = true;
      this.$store
        .dispatch("loadPosts", this.currentPage)
        .then(() => (this.loading = false));
    }
  }

  // computed: {
  //   // mix the getters into computed with object spread operator
  //   ...mapGetters([
  //     'doneTodosCount',
  //     'anotherGetter',
  //     // ...
  //   ])
  // }
}
