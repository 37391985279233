<!--template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view />
  </div>
</template-->
<template lang="pug">
  #app
    #leftbar
      h1#logo fransstudio
      #nav
        router-link.btn(to="/") Blog
        router-link.btn(to="/about") About
        router-link.btn(to="/works") Works
      .social(:class="{open: socialOpen}")
        .block(@click="socialOpen = !socialOpen")
        .inner
          a(href="mailto:info@fransstudio.de").link
            img(src="/assets/img/mail.png" width="38" height="38")
            | info@fransstudio.de
          a(href="https://www.instagram.com/fransstudio" target="_blank").link
            img(src="/assets/img/insta.png" width="38" height="38")
            | @fransstudio
          a.link.link-share-window(@click="shareWindow = !shareWindow" :class="{open: shareWindow}")
            img(src="/assets/img/teilen.png" width="38" height="38")
            | Share
          .share-window(:class="{open: shareWindow}")
            //- a.link clipboard
            a.link(:href="`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`" target="_blank") facebook
            a.link(:href="`https://twitter.com/intent/tweet?text=Check%20Out%20${currentUrl}`" target="_blank") twitter
            a.link(:href="`https://www.reddit.com/submit?url=${currentUrl}`" target="_blank") reddit

      .extras
        .inner
          router-link(to="/impressum") IMPRESSUM
          p All rights reserved <br> © {{new Date().getFullYear()}}

    #content
      router-view

</template>

<style lang="sass">
@import styles/index


$mobile: 900px
=mobile
  @media screen and (max-width: $mobile)
    @content

=mobile-xs
  @media screen and (max-width: 411px)
    @content

#app
  min-height: 100vh
  position: relative
  display: flex
  width: calc(100% - 80px)
  margin: 40px
  align-items: flex-start
  +mobile
    display: block
  +mobile-xs
    margin: 15px
    width: calc(100% - 30px)

#leftbar
  width: 260px
  // margin-right: 50px
  flex-shrink: 0
  position: fixed

  +mobile
    position: relative
    width: 100%
    #nav
      display: flex
      .btn
        flex-grow: 1
    .social
      max-height: 0
      overflow: hidden
      transition: max-height 0.5s
      &.open
        max-height: 500px
    .extras
      .inner a, .inner p
        display: inline-block
        margin-right: 6px
        br
          display: none

  #logo
    text-align: center
    text-transform: uppercase
    overflow: hidden
    +bevel
    margin: 0
    font-size: 25px

  #nav
    font-size: 1.4rem
    background: gray
    // padding: 21px
    +bevel
    text-transform: uppercase
    // border-image-source: url(assets/border-test.png)
    // border-image-slice: 10 fill
    // border-image-repeat: repeat
    .btn
      display: block
      margin-bottom: 10px

  .social
    +bevel
    padding: 12px 5px 10px 4px
    position: relative

    .block
      position: absolute
      background: black
      width: 80%
      height: 10px
      top: 8px
      left: 10%
    .inner
      +yellow-notepad
      .link
        display: block
        font-size: 18px
        margin: 10px 0
        // font-weight: bold
        text-shadow: -1px 1px 0 white
        cursor: pointer
        display: flex
        white-space: nowrap
        align-items: center
        &:hover
          transform: translateY(-2px)
        img
          margin-right: 8px
          flex-shrink: 0

    .link-share-window
      &::after
        content: "ᐅ"
        transform: rotate(90deg)
        display: inline
        font-size: 12px
        transition: transform 0.5s
        transform-origin: 50%
        width: 12px
        height: 12px
        margin: 1px 0 0 3px
      &.open
        &::after
          transform: rotate(270deg)
          margin: -2px 0 0 2px
    .share-window
      position: relative
      max-height: 0
      overflow: hidden
      transition: max-height 1s
      margin-left: 47px
      &.open
        max-height: 300px
      a.link
        margin: 0 0 3px

  .extras
    text-align: center
    position: relative
    font-weight: normal
    +bevel
    +shadow
    padding: 6px  6px 7px

    .inner
      +inset
      padding: 5px
      font-size: 14px
      &, a, p
        // color: rgba(black,0.5)
        text-shadow: -1px 1px 0 white
        font-weight: bold
      a
        &:hover
          color: rgba(0,0,0,0.7)
      p
        margin: 5px 0 0
#content
  border: 6px solid white
  margin-left: 300px
  +shadow
  padding: 30px
  // +white-bevel
  // +border-simple
  // background: #ececec
  background: white
  flex-grow: 1
  min-height: 90vh
  max-width: 900px

  +mobile
    margin-left: 0
</style>

<script>
export default {
  mounted: function () {
    const closeShareWindow = (e) => {
      const container = document.querySelector(".link-share-window");
      if (!container.contains(e.target)) {
        this.shareWindow = false;
      }
    };
    document.addEventListener("click", closeShareWindow);
  },
  data: () => {
    return {
      shareWindow: false,
      socialOpen: false,
    };
  },
  computed: {
    currentUrl: () => window.location.href,
  },
};
</script>
