import { WPService } from "@/services/WPService";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    posts: [],
    postsTotalPages: 1,
    page: {},
    works: [],
    worksTotalPages: 1,
    categories: [],
  },
  mutations: {
    receivePosts(state, { posts, totalPages }) {
      state.posts = posts;
      state.postsTotalPages = +totalPages;
    },
    receivePage(state, page) {
      state.page = page;
    },
    receiveWorks(state, { works, totalPages }) {
      state.works = works;
      state.worksTotalPages = totalPages;
    },
    receiveCategories(state, categories) {
      state.categories = categories;
    },
  },
  actions: {
    async loadPosts({ commit }, currentPage) {
      const data = await WPService.loadPosts(currentPage);
      commit("receivePosts", data);
    },
    async loadPage({ commit }, pageId) {
      const page = await WPService.loadPageById(pageId);
      commit("receivePage", page);
    },
    async loadWorks({ commit }, page) {
      const data = await WPService.loadWorks(page);
      commit("receiveWorks", data);
    },
    async loadCategories({ commit }) {
      const categories = await WPService.loadCategories();
      commit("receiveCategories", categories);
    },
  },
  modules: {},
});
