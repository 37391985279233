import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Blog from "../views/Blog.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/blog/:slug",
    name: "BlogSingle",
    component: () =>
      import(/* webpackChunkName: "blogsingle" */ "../views/BlogSingle.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/works",
    name: "Works",
    component: () =>
      import(/*webpackChunkName: 'portfolio' */ "../views/Works.vue"),
  },
  // remember, children component only make sens if parent component has layout template
  {
    path: "/works/:slug",
    name: "WorksSingle",
    component: () => import("../views/WorksSingle.vue"),
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () => import("../views/Impressum.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
